import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Notification() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/notification`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setNotifications(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  const [selectedNotification, setSelectedNotification] = useState(null);
  const [modal, setModal] = useState(false);
  const openModal = (notification) => {
    setSelectedNotification(notification);
    setModal(true);
  };

  const closeModal = () => {
    setSelectedNotification(null);
    setModal(false);
  };

  return (
    <div className="container mx-auto bg-purple-50">
      <div className="flex flex-col ml-8">
        <div className="flex-row ">
          <h1 className="mb-4 font-semibold text-3xl  mt-4">Notificaciones</h1>
        </div>
        <div className="flex-row mb-4 mt-4">
          <Link
            className="  h-1/2 mt-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
            to={`/notification/create`}
          >
            Crear Notificacion
          </Link>
        </div>
        <div className="relative w-full flex flex-col shadow-lg mb-6 mt-4 bg-purple-50">
          <div className=" flex  bg-transperant flex-nowrap items-center flex-grow">
            <table className="w-full">
              <thead>
                <tr className="border border-solid border-l-0 bg-purple-300  text-left text-gray-700">
                  <th className="w-1/5 pl-8">titulo</th>
                  <th className="w-1/2 pl-8">Texto</th>
                  <th className="w-1/12 pl-8">Accion</th>
                </tr>
              </thead>
              <tbody>
                {notifications.map((notification) => (
                  <tr className="bg-purple-100 hover:bg-purple-200">
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      {notification.title}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      {notification.text}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      <button
                        onClick={() => openModal(notification)}
                        className=" rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                      >
                        ver
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {modal && (
        <div className=" fixed inset-0 bg-black bg-opacity-50 flex  justify-center z-50 ">
          <div className="modal-content text-wrap  bg-purple-50 p-6  shadow-md w-3/4 h-3/4 mt-20 ">
            {selectedNotification.icon && (
              <img
                className="w-12 h-12 rounded-full"
                src={
                  selectedNotification.icon
                    ? `${process.env.REACT_APP_ANDI_API}/public/icon/${selectedNotification.icon}`
                    : null
                }
                alt={selectedNotification.icon}
              />
            )}
            <p className="mb-4 text-center">{selectedNotification.title}</p>
            <div className="text-wrap">
              <p className=" p-2 max-w-max  border border-gray-300 rounded text-wrap ">
                {selectedNotification.text}
              </p>
            </div>
            <div className="flex justify-center">
              <button
                className=" rounded-md bg-purple-600 px-4 py-0 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                onClick={closeModal}
              >
                salir
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Notification;
