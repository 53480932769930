import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TextField, Select } from "@radix-ui/themes";

function UserBenefitModify() {
  const [service, setService] = useState([]);

  const getServices = () => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/services`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setService(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getRegions = () => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/regions`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setRegion(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getPlans = () => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/plans`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setPlans(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getUserBenefitById = () => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/benefit/${id}`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setUser(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getPlanByUserId = () => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/plan/${id}`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setUserPlan(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  useEffect(() => {
    getServices();
    getRegions();
    getPlans();
    getUserBenefitById();
  }, []);

  const fechaActual = new Date();
  const fechaDespuesDuracion = new Date(fechaActual);
  const [endDate, setEndDate] = useState();
  const [userPlan, setUserPlan] = useState({});
  const [planBolean, setPlanBolean] = useState(false);
  const { id } = useParams();
  const [region, setRegion] = useState([]);
  const [plans, setPlans] = useState([]);
  const [errors, setErrors] = useState([]);
  const [correct, setcorrect] = useState();
  const [user, setUser] = useState({
    firstName: "",
    secondName: "",
    lastName: "",
    secondLastName: "",
    serviceId: 1,
    phone: "",
    sisCode: "",
    email: "",
    enabled: true,
    regionId: 7,
    carnetSerialNumber: "",
    rut: "",
    isVerified: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "enable" ? value === "true" : value;

    setUser((user) => ({
      ...user,
      [name]: newValue,
    }));
  };

  const handleSelectChange = (property, value) => {
    setUser((user) => ({
      ...user,
      [property]: value,
    }));
  };

  function updateUserBenefit(id, user) {
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/benefit/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
        body: JSON.stringify({
          firstName: user.firstName,
          lastName: user.lastName,
          secondLastName: user.secondLastName,
          secondName: user.secondName,
          serviceId: parseInt(user.serviceId),
          phone: user.phone,
          sisCode: user.sisCode,
          email: user.email,
          enabled: user.enabled,
          regionId: parseInt(user.regionId),
          carnetSerialNumber: user.carnetSerialNumber,
          rut: user.rut,
          isVerified: user.isVerified,
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
          throw new Error("La solicitud PUT falló");
        }
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }
  function eliminarDoc(documentId) {
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/document/${documentId}`,
      {
        method: "delete",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
      }
    )
      .then((response) => {
        if (response.status == 400) {
          response.json().then((data) => {});
        }
        if (response.status == 200) {
          setDocuments(documents.filter((doc) => doc.id !== documentId));
        }
        if (!response.ok) {
          throw new Error("La solicitud DELETE falló");
        }
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud DELETE:", error);
      });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setcorrect(null);
    setErrors([]);
    updateUserBenefit(id, user);
  };

  const handleInputPlanChange = (event) => {
    const { name, value } = event.target;
    var duration = userPlan.planUserDuration;
    setPlanBolean(true);
    delete userPlan.planUserStartDate;
    delete userPlan.planUserEndDate;
    if (!isNaN(value)) {
      var plan = plans.find((plan) => plan.id === parseInt(value));
      var duration = plan.duration;
    }
    setUserPlan({ ...userPlan, [name]: value });
    fechaDespuesDuracion.setDate(fechaDespuesDuracion.getDate() + duration);
    setEndDate(fechaDespuesDuracion.toLocaleDateString());
  };

  const fechaFormateadaActual = fechaActual.toLocaleDateString();
  useEffect(() => {
    var duration = user.planUserName ? user.planUserName : 0;
    const fechaDespuesDuracion = new Date();
    fechaDespuesDuracion.setDate(fechaDespuesDuracion.getDate() + duration);
    setEndDate(fechaDespuesDuracion.toLocaleDateString());
  }, []);

  const handleSubmitPlan = (event) => {
    event.preventDefault();
    setcorrect(null);
    setErrors([]);
    cambiarPlan(id, userPlan);
  };
  function cambiarPlan(id, userPlan) {
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/plan/${id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
        body: JSON.stringify({
          planId: Number(userPlan.planUserId),
          endDate: endDate,
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
          throw new Error("La solicitud PUT falló");
        }
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }

  useEffect(() => {
    const formFile = document.getElementById("formData");
    if (formFile) {
      const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        try {
          await fetch(
            `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/document/${id}`,
            {
              method: "PUT",
              body: formData,
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("andi-session")).token,
            }
          );
        } catch (error) {
          console.error("Error al enviar el formulario", error);
        }
        fetch(
          `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/document/${id}`,
          {
            headers: {
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("andi-session")).token,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            setDocuments(res);
          })
          .catch((ex) => {
            console.error(ex);
          });
      };

      formFile.addEventListener("submit", handleSubmit);
      return () => {
        formFile.removeEventListener("submit", handleSubmit);
      };
    }
  }, []);

  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/document/${id}`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setDocuments(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  return (
    <div className="container mx-auto bg-purple-50">
      <div>
        <h1 className=" text-3xl font-semibold  p-4 ml-4 ">
          Modificar Prestador
        </h1>
        {errors.length > 0 && (
          <div role="alert" className="p-4">
            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Error
            </div>
            {errors.map((data) => (
              <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 w-80">
                <p>
                  {data.code}: {data.message}
                </p>
              </div>
            ))}
          </div>
        )}
        {correct === 200 ? (
          <div role="alert" className="p-4">
            <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2 w-80">
              modificado
            </div>

            <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 w-80">
              <p>el Prestador fue modificado correctamente</p>
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row mr-8 ml-8">
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Primer Nombre
            </p>
            <TextField.Root
              type="text"
              className="w-48"
              name="firstName"
              value={user.firstName}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-8 mr-8">
            <p>Segundo Nombre</p>
            <TextField.Root
              type="text"
              className="w-48"
              name="secondName"
              value={user.secondName}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Primer Apellido
            </p>
            <TextField.Root
              type="text"
              className="w-48"
              name="lastName"
              value={user.lastName}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-8 mr-8">
            <p>Segundo Apellido</p>
            <TextField.Root
              type="text"
              className="w-48"
              name="secondLastName"
              value={user.secondLastName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="flex flex-row mr-8 ml-8">
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              SIS
            </p>
            <TextField.Root
              type="text"
              className="w-48"
              name="sisCode"
              value={user.sisCode}
              onChange={handleInputChange}
              maxLength={6}
              placeholder={`${user.sisCode}`}
            />
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Región
            </p>
            <Select.Root
              value={user.regionId}
              onValueChange={(val) => handleSelectChange("regionId", val)}
            >
              <Select.Trigger
                name="region"
                placeholder="Regiones"
                value={user.regionId}
                className="!min-w-48 !max-w-48"
              />
              <Select.Content>
                <Select.Group>
                  {region.map((r, index) => (
                    <Select.Item key={index} value={r.id}>
                      {r.name}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Rut
            </p>
            <TextField.Root
              className="w-48"
              type="text"
              name="rut"
              value={user.rut}
              onChange={handleInputChange}
              placeholder={`${user.rut}`}
            />
          </div>

          <div className="mb-8 mr-8">
            <p>Servicio</p>
            <Select.Root
              value={user.serviceId}
              onValueChange={(val) => handleSelectChange("serviceId", val)}
            >
              <Select.Trigger
                placeholder="Servicios"
                className="!min-w-48 !max-w-48"
                value={user.serviceId}
              />
              <Select.Content>
                <Select.Group>
                  {service.map((r, index) => (
                    <Select.Item key={index} value={r.id}>
                      {r.name}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
        </div>

        <div className="flex flex-row mr-8 ml-8">
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Email
            </p>
            <TextField.Root
              type="email"
              className="w-48"
              name="email"
              value={user.email}
              onChange={handleInputChange}
              placeholder={`${user.email}`}
            />
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Número de documento
            </p>
            <TextField.Root
              type="text"
              className="w-48"
              name="carnetSerialNumber"
              value={user.carnetSerialNumber}
              onChange={handleInputChange}
              maxLength={11}
              placeholder={`${user.carnetSerialNumber}`}
            />
          </div>
          <div className="mb-8 mr-8">
            <p>Verificado</p>
            <Select.Root
              value={user.isVerified}
              onValueChange={(val) => handleSelectChange("isVerified", val)}
            >
              <Select.Trigger
                placeholder="Es verificado?"
                className="!min-w-48 !max-w-48"
                value={user.isVerified}
              />
              <Select.Content>
                <Select.Group>
                  {[
                    { value: false, label: "No" },
                    { value: true, label: "Si" },
                  ].map((r, index) => (
                    <Select.Item key={index} value={r.value}>
                      {r.label}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
          <div className="mb-8">
            <p>Teléfono</p>
            <TextField.Root
              type="number"
              className="w-48"
              name="phone"
              value={user.phone}
              onChange={handleInputChange}
              placeholder={`${user.phone || "0"}`}
            />
          </div>
        </div>

        <div className="flex flex-row mr-8 ml-8">
          <div className="mb-8">
            <p>Estado</p>
            <Select.Root
              value={user.enabled}
              onValueChange={(val) => handleSelectChange("enabled", val)}
            >
              <Select.Trigger
                placeholder="Estado"
                className="!min-w-48 !max-w-48"
                value={user.enabled}
              />
              <Select.Content>
                <Select.Group>
                  {[
                    { value: false, label: "Deshabilitado" },
                    { value: true, label: "Habilitado" },
                  ].map((r, index) => (
                    <Select.Item key={index} value={r.value}>
                      {r.label}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="mb-8 ml-8">
          <button
            type="butom"
            onClick={handleSubmit}
            className="rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-600"
          >
            Guardar Cambios
          </button>
        </div>
      </div>
      <div className="border-y-4 border-purple-900 ">
        <p className="text-center m-2 text-lg">Información adicional</p>
      </div>
      <div className="flex">
        <div className="flex-col mb-8">
          <div className=" mt-4 ml-8 mb-2">
            <p>Información del Plan</p>
          </div>
          <div className=" ml-8 mr-8 mb-8">
            <p>Plan</p>
          </div>
        </div>
      </div>
      {/* <div>
        <div className="border-y-4 border-purple-900  ">
          <p className="text-center m-2 text-lg text-purple-950">Documentos</p>
        </div>
        <div className=" mt-4 ml-8 mb-2">
          <p>Agregar Documento</p>
          <div>
            <form id="formData">
              <TextField.Root
                type="file"
                className="pb-1 border-none"
                name="document_file"
                id="idFoto"
              />

              <button
                type="submit"
                className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-green-500"
              >
                agregar
              </button>
            </form>
          </div>
        </div>
        <div>
          <table className="w-full">
            <thead>
              <tr className="px-4">
                <th className="border-b border-purple-200 pr-80 pl-3 text-left ">
                  Archivo
                </th>
                <th className="border-b border-purple-200 px-10 text-left ">
                  Acción
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-900  w-1/2">
              {documents &&
                documents.map((document, index) => (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="pl-3 border-b border-gray-200">
                      {document.fileName}
                    </td>
                    <td className="pl-3 pb-1 border-b border-gray-200">
                      <a
                        target="_blank"
                        download="documento"
                        href={`${process.env.REACT_APP_ANDI_API}/public/documents/${document.fileName}`}
                        className="rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                      >
                        Descargar
                      </a>
                      <button
                        type="submit"
                        className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-red-600"
                        onClick={() => eliminarDoc(document.id)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
}

export default UserBenefitModify;
