import { useEffect, useState } from "react";
import React from "react";
import Select from "react-select";
function PaymentCreate() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/benefit`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setUsers(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  const [Plan, setPlans] = useState([]);
  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/plans`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setPlans(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);
  const [paymentCreate, setPaymentCreate] = useState({
    userBenefitId: "",
    transactionId: "",
    transactionDate: "",
    planId: -1,
    status: 1,
    amount: 1,
  });
  const [errors, setErrors] = useState([]);
  const [correct, setcorrect] = useState(null);
  function createNewPayment() {
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/payment`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },

        body: JSON.stringify({
          userBenefitId: paymentCreate.userBenefitId,
          transactionId: paymentCreate.transactionId,
          transactionDate: paymentCreate.transactionDate,
          planId: parseInt(paymentCreate.planId),
          status: parseInt(paymentCreate.status),
          amount: parseInt(paymentCreate.amount),
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
          throw new Error("La solicitud Post fallo");
        }
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPaymentCreate({
      ...paymentCreate,
      [name]: value,
    });
  };
  const handleInputSelectChange = (event) => {
    const { name, value } = event;
    setPaymentCreate({
      ...paymentCreate,
      [name]: value,
    });
  };
  const handleSubmitButtom = () => {
    //event.preventDefault();
    setCleared(false);
    setcorrect(null);
    setErrors([]);
    createNewPayment();
  };

  const options = users.map((user) => ({
    value: user.id,
    label: user.name,
    name: "userBenefitId",
  }));

  const [paymentCreateClear, setPaymentCreateClear] = useState({
    userBenefitId: "",
    transactionId: "",
    transactionDate: "",
    planId: -1,
    status: 1,
    amount: 1,
  });
  const [cleared, setCleared] = useState(false);
  useEffect(() => {
    if (correct === 200 && !cleared) {
      setPaymentCreate(paymentCreateClear);
      setCleared(true);
    }
  }, [correct, setPaymentCreate, paymentCreateClear]);
  return (
    <div className="container mx-auto bg-purple-50">
      <h1 className=" text-3xl font-semibold  ml-8 pb-4 pt-4 ">Crear Pago</h1>
      <div className="ml-4 mb-2">
        {errors.length > 0 && (
          <div role="alert" className="p-4">
            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Error
            </div>
            {errors.map((data) => (
              <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 w-80">
                <p>
                  {data.code}: {data.message}
                </p>
              </div>
            ))}
          </div>
        )}
        {correct === 200 && (
          <div role="alert" className="p-4">
            <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Creado
            </div>

            <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 w-80">
              <p>El pago fue creado correctamente</p>
            </div>
          </div>
        )}
      </div>
      <div className="flex ml-8">
        <div className="flex-col mr-8 ">
          <div className="mb-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Prestador
            </p>
            <Select
              name="userBenefitId"
              //value={paymentCreate.userBenefitId}
              onChange={handleInputSelectChange}
              className="bg-purple-50  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
              options={options}
            ></Select>
          </div>
          <div className="mb-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Fecha transaccion
            </p>
            <input
              type="date"
              placeholder="agregar fecha"
              value={paymentCreate.transactionDate}
              className="w-48 bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block rounded-md sm:text-sm focus:ring-1 "
              onChange={handleInputChange}
              name="transactionDate"
            ></input>
          </div>
          <div className="mb-4">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Plan
            </p>
            <select
              name="planId"
              onChange={handleInputChange}
              value={paymentCreate.planId}
              className="w-48 bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block rounded-md sm:text-sm focus:ring-1 "
            >
              <option value={-1}>seleccione una opcion</option>
              {Plan.map((plan) => (
                <option value={plan.id}>{plan.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex-col mr-8">
          <div className="mb-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Id Transaccíon
            </p>
            <input
              name="transactionId"
              type="text"
              onChange={handleInputChange}
              value={paymentCreate.transactionId}
              placeholder="agregar Id de transaccion "
              className="w-48 bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block rounded-md sm:text-sm focus:ring-1 "
            ></input>
          </div>
          <div className="mb-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Estado
            </p>
            <select
              name="status"
              onChange={handleInputChange}
              value={paymentCreate.status}
              className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
            >
              <option value={1}>Pendiente</option>
              <option value={0}>Pagado</option>
              <option value={2}>Error</option>
            </select>
          </div>
          <div>
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Monto
            </p>
            <input
              type="number"
              name="amount"
              min="1"
              value={paymentCreate.amount}
              onChange={handleInputChange}
              className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
              placeholder="agregar monto"
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={handleSubmitButtom}
        className="ml-8 mb-4  mt-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-indigo-600"
      >
        Crear pago
      </button>
    </div>
  );
}

export default PaymentCreate;
