import { Outlet } from "react-router-dom";
import Nav from "./Nav";


export default function Main() {
  return(
    <div>
      <Nav/>  
      {process.env.REACT_APP_REACT_APP_ANDI_API}
      <Outlet/>
    </div>
  )
}
