import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Surveys() {
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/surveys`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setSurveys(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);
  return (
    <div className="container mx-auto bg-purple-50">
      <div className="flex flex-col ml-8">
        <div className="flex-row ">
          <h1 className="mb-4 font-semibold text-3xl  mt-4">Encuestas</h1>
        </div>
        <div className="flex-row">
          <Link
            className=" h-1/2 mt-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
            to={`/surveys/create`}
          >
            Crear Encuesta
          </Link>
        </div>
        <div className="relative w-full flex flex-col shadow-lg mb-6 mt-4 bg-purple-50">
          <div className=" flex  bg-transperant flex-nowrap items-center flex-grow">
            <table className="w-full">
              <thead>
                <tr className="border border-solid border-l-0 bg-purple-300  text-left text-gray-700">
                  <th className="w-10/12 pl-8">Encuesta</th>
                  <th className=" border-purple-400 w-1/12 pl-8 ">Accion</th>
                </tr>
              </thead>
              <tbody>
                {surveys.map((survey) => (
                  <tr className="border-b border-purple-300 bg-purple-100  hover:bg-purple-200 text-wrap">
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase ">
                      {survey.question}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      <Link
                        className=" rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                        to={`/surveys/surveysUsers/${survey.id}`}
                      >
                        Ver
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Surveys;
