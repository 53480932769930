import * as React from "react";
import { createRoot } from "react-dom/client";
import Main from "./Main";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import UserBenefit from "./benefit/UserBenefit";
import UserBenefitModify from "./benefit/UserBenefitModify";
import UserBenefitCreate from "./benefit/UserBenefitCreate";
import Payment from "./payment/Payment";
import PaymentModify from "./payment/PaymentModify";
import PaymentCreate from "./payment/PaymentCreate";
import Plan from "./plans/Plan";
import PlanModify from "./plans/PlanModify";
import PlanCreate from "./plans/PlanCreate";
import Surveys from "./surveys/Surveys";
import SurveysCreate from "./surveys/SurveysCreate";
import SurveysUsers from "./surveys/SurveysUsers";
import Notification from "./notification/Notification";
import NotificationCreate from "./notification/NotificacionCreate";
import "./index.css";
import Login from "./Login";
import * as jose from "jose";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";

const useAuth = async () => {
  try {
    const session = localStorage.getItem("andi-session");

    if (!session) throw Error("Cannot access to this resource");

    const token = JSON.parse(session).token;
    const publicKeyPem = process.env.REACT_APP_PUBLIC_KEY.replace(/\\n/g, "");

    console.log("public key pem", publicKeyPem);

    const publicKey = await jose.importSPKI(publicKeyPem, "RS256");

    console.log("public key", publicKey);

    await jose.jwtVerify(token, publicKey, {
      algorithms: ["RS256"],
    });

    return null;
  } catch (error) {
    console.error(error);

    localStorage.removeItem("andi-session");

    return redirect("/login");
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Theme>
        <Main />
      </Theme>
    ),
    loader: useAuth,
    children: [
      {
        path: "benefit",
        element: <UserBenefit />,
      },
      {
        path: "benefit/modify/:id",
        element: <UserBenefitModify />,
      },
      {
        path: "benefit/createUserBenefit",
        element: <UserBenefitCreate />,
      },
      {
        path: "plans",
        element: <Plan />,
      },
      {
        path: "plans/modify/:id",
        element: <PlanModify />,
      },
      {
        path: "plans/create",
        element: <PlanCreate />,
      },
      {
        path: "payments",
        element: <Payment />,
      },
      {
        path: "payments/create",
        element: <PaymentCreate />,
      },
      {
        path: "payments/modify/:id",
        element: <PaymentModify />,
      },
      {
        path: "notification",
        element: <Notification />,
      },
      {
        path: "notification/create",
        element: <NotificationCreate />,
      },
      {
        path: "surveys",
        element: <Surveys />,
      },
      {
        path: "surveys/create",
        element: <SurveysCreate />,
      },
      {
        path: "surveys/SurveysUsers/:id",
        element: <SurveysUsers />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    loader: () => {
      if (localStorage.getItem("andi-session")) return redirect("/");
      else return null;
    },
  },
]);
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
