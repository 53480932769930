import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function cambiarURL(id) {
  window.location.href = `/payments/modify/${id}`;
}

function Payment() {
  const [payments, setPayments] = useState([]);
  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/payment`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setPayments(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentsPerPage, setPaymentsPerPage] = useState(5);
  function handlePaymentsPerPageChange(event) {
    setPaymentsPerPage(Number(event.target.value));
    setCurrentPage(1);
  }
  function renderizarBotonesPaginacion() {
    const totalPages = Math.ceil(payments.length / paymentsPerPage);
    const botones = [];

    for (let i = 1; i <= totalPages; i++) {
      botones.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`mr-2 ${
            currentPage === i
              ? "bg-purple-600 text-white"
              : "bg-purple-200 text-purple-900"
          } px-3 py-1 rounded-md`}
        >
          {i}
        </button>
      );
    }

    return botones;
  }
  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = payments.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  const [ordenAscendente, setOrdenAscendente] = useState(true);

  function ordenarPorColumnaString(number) {
    const tablaCuerpo = document.getElementById("tablaCuerpo");

    // Comprobar si se encontró el elemento con el ID "tablaCuerpo"
    if (!tablaCuerpo) return;

    const filas = Array.from(tablaCuerpo.getElementsByTagName("tr"));

    // Eliminar el slice(1) para incluir todas las filas, incluida la primera fila (encabezados)
    const datosFilas = filas.map((fila) => ({
      fila,
      valor: fila.getElementsByTagName("td")[number].textContent,
    }));

    // Ordenar los datos por el valor de la columna especificada
    datosFilas.sort((a, b) => {
      // Comparar las cadenas de texto utilizando localeCompare
      return ordenAscendente
        ? a.valor.localeCompare(b.valor)
        : b.valor.localeCompare(a.valor);
    });

    // Limpiar la tabla antes de reordenar las filas
    while (tablaCuerpo.firstChild) {
      tablaCuerpo.removeChild(tablaCuerpo.firstChild);
    }

    // Reordenar las filas en el cuerpo de la tabla
    for (const { fila } of datosFilas) {
      tablaCuerpo.appendChild(fila);
    }

    // Cambiar la dirección del orden para la próxima vez
    setOrdenAscendente(!ordenAscendente);
  }

  function ordenarPorColumnaNumber(number) {
    const tablaCuerpo = document.getElementById("tablaCuerpo");

    // Comprobar si se encontró el elemento con el ID "tablaCuerpo"

    const filas = Array.from(tablaCuerpo.getElementsByTagName("tr"));

    // Eliminar el slice(1) para incluir todas las filas, incluida la primera fila (encabezados)
    const datosFilas = filas.map((fila) => ({
      fila,
      valor: parseFloat(fila.getElementsByTagName("td")[number].textContent),
    }));

    // Ordenar los datos por el valor de la columna especificada
    datosFilas.sort((a, b) =>
      ordenAscendente ? a.valor - b.valor : b.valor - a.valor
    );

    // Reordenar las filas en el cuerpo de la tabla
    for (const { fila } of datosFilas) {
      tablaCuerpo.appendChild(fila);
    }

    // Cambiar la dirección del orden para la próxima vez
    setOrdenAscendente(!ordenAscendente);
  }

  return (
    <>
      <div className="relative w-full flex flex-col shadow-lg mb-6 mt-4 bg-purple-50">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full flex-grow flex">
            <h1 className="mb-4 font-semibold text-3xl ml-4">Pagos</h1>
          </div>
          <div className="relative w-full px-4 max-w-full flex-grow flex mb-4 ">
            <Link
              className=" ml-4 h-1/2 mt-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
              to={`/payments/create`}
            >
              Crear Pago
            </Link>
          </div>

          <div className="ml-8 ">
            <label>
              cantidad de busqueda
              <select
                id="paymentsPerPage"
                name="paymentsPerPage"
                value={paymentsPerPage}
                onChange={handlePaymentsPerPageChange}
                class=" bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block rounded-md sm:text-sm focus:ring-1 "
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </label>
          </div>
        </div>
        <div className="block bg-transperant m-4 p-4 w-full overflow-x-auto ">
          <table className="w-full">
            <thead>
              <tr className="border border-solid border-l-0 bg-purple-300  text-center  ">
                <th
                  scope="col"
                  class="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider"
                >
                  Foto
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider"
                >
                  <button onClick={() => ordenarPorColumnaString(1)}>
                    Prestador
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-md px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider"
                >
                  Fecha de Transacción
                </th>
                <th
                  scope="col"
                  class="text-md px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider pl-10"
                >
                  <button onClick={() => ordenarPorColumnaString(3)}>
                    Plan
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-md px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider pl-7"
                >
                  <button onClick={() => ordenarPorColumnaString(4)}>
                    ID Transacción
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-md px-6 py-3  text-xs  text-gray-700 uppercase tracking-wider pl-8"
                >
                  <button onClick={() => ordenarPorColumnaNumber(5)}>
                    Estado
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-md px-6 py-3  text-xs font-medium text-gray-700 uppercase tracking-wider pl-10"
                >
                  <button onClick={() => ordenarPorColumnaNumber(6)}>
                    Monto
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-md px-6 py-3  text-xs font-medium text-gray-700 uppercase tracking-wider pl-15"
                >
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPayments.map((payment) => (
                <tr className=" text-md px-6 py-3  text-center border-b border-purple-300 bg-purple-100  hover:bg-purple-200">
                  <td class=" px-4 text-center whitespace-nowrap   ">
                    {payment.image && (
                      <img
                        className="w-12 h-12 rounded-full"
                        src={`${process.env.REACT_APP_ANDI_API}/public/avatar/${payment.image}`}
                      />
                    )}
                  </td>
                  <td class=" text-center whitespace-nowrap  ">
                    {payment.rutBenefit}
                  </td>
                  <td class="text-center whitespace-nowrap ">
                    {payment.transactionDate}
                  </td>
                  <td class=" text-center whitespace-nowrap ">
                    {payment.plan}
                  </td>
                  <td class=" text-center whitespace-nowrap ">
                    {payment.transactionId}
                  </td>
                  <td class=" text-center whitespace-nowrap  ">
                    {payment.status}
                  </td>
                  <td class="text-center whitespace-nowrap ">
                    ${payment.amount}
                  </td>
                  <td class=" text-center whitespace-nowrap ">
                    <Link
                      className=" h-1/2 mt-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                      to={`/payments/modify/${payment.id}`}
                    >
                      editar
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-center pb-4">
          {renderizarBotonesPaginacion()}
        </div>
      </div>
    </>
  );
}

export default Payment;
