import { useState } from "react";
import axios from "axios";
import Alert from "./components/basic/Alert";
import Button from "./components/basic/Button";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onLogin = async () => {
    setIsLoading(true);

    const response = await axios.post(
      `${process.env.REACT_APP_ANDI_API}/api/v1/auth`,
      {
        email: loginForm.email,
        password: loginForm.password,
      },
      { validateStatus: (status) => status < 500 }
    );

    if (response.status === 200 && response.data.isAdmin) {
      localStorage.setItem("andi-session", JSON.stringify(response.data));
      navigate("/");
      return;
    }

    setTimeout(() => {
      setShowAlertMessage(true);
      setIsLoading(false);
    }, 1000);
  };

  const handleForm = (event) => {
    setLoginForm({ ...loginForm, [event.target.name]: event.target.value });
  };

  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      onLogin();
    }
  };

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center">
        <div className="my-10">
          <Link to={"/"}>
            <img
              src="/img/logo-white.png"
              alt="login logo"
              className="h-40 drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]"
            />
          </Link>
        </div>
        <div className="bg-white rounded px-10 py-10 shadow-lg w-full md:w-[500px]">
          <div className="text-center mb-8">Acceso Prestadores</div>
          {showAlertMessage && (
            <Alert
              title={"Lo sentimos."}
              message={"El correo electrónico y la contraseña no coinciden."}
              type="warn"
              onCloseAlert={() => setShowAlertMessage(false)}
            />
          )}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-xs leading-6 text-gray-900 font-bold"
            >
              Correo electrónico
            </label>
            <input
              type="text"
              name="email"
              id="email"
              className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="ejemplo@gmail.com"
              onKeyDown={handleKeyPressed}
              value={loginForm.email}
              onChange={handleForm}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-xs leading-6 text-gray-900 font-bold"
            >
              Contraseña
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={loginForm.password}
              onKeyDown={handleKeyPressed}
              onChange={handleForm}
              className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="flex justify-center pt-20">
            <Button
              isLoading={isLoading}
              onClick={onLogin}
              label={"Ingresar"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
