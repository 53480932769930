import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";

function PaymentModify() {
  const [plans, setPlans] = useState([]);
  const [payment, setPayments] = useState({
    amount: 0,
    name: "",
    plan: "",
    planId: 0,
    status: 0,
    transactionDate: "",
    transactionId: "",
    userId: "",
  });
  const [errors, setErrors] = useState([]);
  const [correct, setcorrect] = useState(null);
  const [options, setOptions] = useState([
    { label: "", value: "", name: "userId" },
  ]);
  const [users, setUsers] = useState([]);
  const [userBenefitSelected, setUserBenefitSelected] = useState({
    label: "",
    value: "",
    name: "userId",
  });

  useEffect(() => {
    getPlans();
    getUsers();
  }, []);

  const getPlans = () => {
    if (plans.length == 0) {
      const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/plans`;
      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setPlans(res);
        })
        .catch((ex) => {
          console.error(ex);
        });
    }
  };

  const getUsers = () => {
    if (users.length == 0) {
      const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/benefit`;
      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setUsers(res);
          const auxOptions = res.map((user) => ({
            value: user.id,
            label: user.name,
            name: "userId",
          }));
          setOptions(auxOptions);
          getPayemnt(auxOptions);
        })
        .catch((ex) => {
          console.error(ex);
        });
    }
  };

  const getPayemnt = (auxOptions) => {
    if (payment.userId == "") {
      fetch(
        `${process.env.REACT_APP_ANDI_API}/api/v1/admin/payment/${id}`,
        {
          headers: {
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("andi-session")).token,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setPayments(res);
          console.log(auxOptions);
          setUserBenefitSelected(
            auxOptions.find((option) => {
              return option.value == res.userId;
            })
          );
        })
        .catch((ex) => {
          console.error(ex);
        });
    }
  };

  let { id } = useParams();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPayments({
      ...payment,
      [name]: value,
    });
  };
  function cambiarDatos(id, payment) {
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/payment/${id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
        body: JSON.stringify({
          userId: payment.userId,
          transactionId: payment.transactionId,
          transactionDate: payment.transactionDate,
          planId: parseInt(payment.planId),
          status: parseInt(payment.status),
          amount: parseInt(payment.amount),
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
          throw new Error("La solicitud PUT falló");
        }
      })

      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setcorrect(null);
    setErrors([]);
    cambiarDatos(id, payment);
  };

  const handleInputSelectChange = (event) => {
    const { name, value } = event;
    setPayments({
      ...payment,
      [name]: value,
    });
    setUserBenefitSelected(event);
  };

  return (
    <div className="container mx-auto">
      <div>
        <h1 className="text-2xl mb-4 mt-4 ml-4">Modificar Pago</h1>
        {errors.length > 0 && (
          <div role="alert" className="p-4">
            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Error
            </div>
            {errors.map((data) => (
              <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 w-80">
                <p>
                  {data.code}: {data.message}
                </p>
              </div>
            ))}
          </div>
        )}
        {correct === 200 && (
          <div role="alert" className="p-4">
            <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2 w-80">
              modificado
            </div>

            <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 w-80">
              <p>el dato fue modificado correctamente</p>
            </div>
          </div>
        )}
        <div className="flex ml-4 ">
          <div className="flex-col mr-8 ">
            <div className="mb-4">
              <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
                Prestador
              </p>
              <Select
                name="userId"
                value={userBenefitSelected}
                onChange={handleInputSelectChange}
                className="bg-purple-50  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
                options={options}
              ></Select>
            </div>

            <label className="block  mb-4">
              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                Fecha Transaccíon
              </span>
              <input
                type="date"
                value={payment.transactionDate}
                name="transactionDate"
                onChange={handleInputChange}
                className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
                placeholder={`${payment.transactionDate}`}
              />
            </label>
            <label className=" mb-4">
              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                Plan
              </span>
              <select
                name="planId"
                value={payment.planId}
                onChange={handleInputChange}
                autoComplete="country-name"
                className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
              >
                {plans.map((plan) => (
                  <option value={plan.id} key={plan.id}>
                    {plan.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="flex-col mr-8 ">
            <div className="mb-4">
              <label className="block mb-4">
                <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                  Id Transaccíon
                </span>
                <input
                  type="text"
                  value={payment.transactionId}
                  name="transactionId"
                  onChange={handleInputChange}
                  className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-49 h-12 rounded-md sm:text-sm focus:ring-1"
                  placeholder={`${payment.transactionId}`}
                />
              </label>
            </div>
            <div className="mb-4">
              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                Estado
              </span>
              <select
                name="status"
                value={payment.status}
                autoComplete="country-name"
                onChange={handleInputChange}
                className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
              >
                <option value={0}>Pagado</option>
                <option value={1}>Pendiente</option>
                <option value={2}>Error</option>
              </select>
            </div>

            <label className="block mb-4">
              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                Monto
              </span>
              <input
                type="number"
                name="amount"
                value={payment.amount}
                onChange={handleInputChange}
                min={1}
                className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
                placeholder={`${payment.amount}`}
              />
            </label>
          </div>
        </div>

        <div className="pl-3">
          <button
            type="submit"
            onClick={handleSubmit}
            className=" rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-indigo-600"
          >
            Guardar Cambios
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentModify;
