import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clpNumberFormat } from "../utils/utils";

function Plan() {
  function cambiarStatus(id, originState) {
    const state = !originState;
    const updatedPlan = plans.map((plan) => {
      if (plan.id === id) {
        return { ...plan, enable: state };
      } else {
        return plan;
      }
    });

    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/plans/enable/${id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
        body: JSON.stringify({
          state: state,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("La solicitud PUT falló");
        }
        if (response.status == 200) {
          setPlans(updatedPlan);
        }
      })

      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/plans`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setPlans(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  const [ordenAscendente, setOrdenAscendente] = useState(true);
  function ordenarPorboolean() {
    const tablaCuerpo = document.getElementById("tablaCuerpo");
    const filas = Array.from(tablaCuerpo.getElementsByTagName("tr"));

    // Eliminar el slice(1) para incluir todas las filas, incluida la primera fila (encabezados)
    const datosFilas = filas.map((fila) => ({
      fila,
      estado:
        fila.getElementsByTagName("td")[2].textContent.trim() === "Activo",
    }));

    // Ordenar los datos por estado (activos primero, inactivos después)
    datosFilas.sort((a, b) => (a.estado === b.estado ? 0 : a.estado ? -1 : 1));

    // Reordenar las filas en el cuerpo de la tabla
    for (const { fila } of datosFilas) {
      tablaCuerpo.appendChild(fila);
    }
  }

  function ordenarPorColumnaString(number) {
    const tablaCuerpo = document.getElementById("tablaCuerpo");

    // Comprobar si se encontró el elemento con el ID "tablaCuerpo"
    if (!tablaCuerpo) return;

    const filas = Array.from(tablaCuerpo.getElementsByTagName("tr"));

    // Eliminar el slice(1) para incluir todas las filas, incluida la primera fila (encabezados)
    const datosFilas = filas.map((fila) => ({
      fila,
      valor: fila.getElementsByTagName("td")[number].textContent,
    }));

    // Ordenar los datos por el valor de la columna especificada
    datosFilas.sort((a, b) => {
      // Comparar las cadenas de texto utilizando localeCompare
      return ordenAscendente
        ? a.valor.localeCompare(b.valor)
        : b.valor.localeCompare(a.valor);
    });

    // Limpiar la tabla antes de reordenar las filas
    while (tablaCuerpo.firstChild) {
      tablaCuerpo.removeChild(tablaCuerpo.firstChild);
    }

    // Reordenar las filas en el cuerpo de la tabla
    for (const { fila } of datosFilas) {
      tablaCuerpo.appendChild(fila);
    }

    // Cambiar la dirección del orden para la próxima vez
    setOrdenAscendente(!ordenAscendente);
  }

  function ordenarPorColumnaNumber(number) {
    const tablaCuerpo = document.getElementById("tablaCuerpo");

    // Comprobar si se encontró el elemento con el ID "tablaCuerpo"

    const filas = Array.from(tablaCuerpo.getElementsByTagName("tr"));

    // Eliminar el slice(1) para incluir todas las filas, incluida la primera fila (encabezados)
    const datosFilas = filas.map((fila) => ({
      fila,
      valor: parseInt(fila.getElementsByTagName("td")[number].textContent),
    }));

    // Ordenar los datos por el valor de la columna especificada
    datosFilas.sort((a, b) =>
      ordenAscendente ? a.valor - b.valor : b.valor - a.valor
    );

    // Reordenar las filas en el cuerpo de la tabla
    for (const { fila } of datosFilas) {
      tablaCuerpo.appendChild(fila);
    }

    // Cambiar la dirección del orden para la próxima vez
    setOrdenAscendente(!ordenAscendente);
  }
  return (
    <>
      <div className="relative w-full flex flex-col shadow-lg mb-6 mt-4 bg-purple-50">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full flex-grow flex">
            <h1 className=" text-3xl font-semibold  p-4 ">Planes</h1>
          </div>
          <div className="ml-8">
            <Link
              className=" h-1/2 mt-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
              to={`/plans/create`}
            >
              Crear plan
            </Link>
          </div>
        </div>
        <div className="mt-4 flex justify-center bg-purple-50">
          <div className="block bg-transperant m-4 p-4 w-full overflow-x-auto ">
            <table className="w-full">
              <thead>
                <tr className="border border-solid border-l-0 bg-purple-300  text-center  ">
                  <th className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                    <button onClick={() => ordenarPorColumnaString(0)}>
                      Nombre
                    </button>
                  </th>
                  <th className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                    <button onClick={() => ordenarPorColumnaString(1)}>
                      Precio
                    </button>
                  </th>
                  <th className="px-6 py-3 text-xs text-gray-700 uppercase tracking-wider">
                    <button onClick={() => ordenarPorColumnaNumber(2)}>
                      Días
                    </button>
                  </th>
                  <th className="px-6 py-3 text-xs text-gray-700 uppercase tracking-wider">
                    <button onClick={ordenarPorboolean}>Estado</button>
                  </th>
                  <th className="px-6 py-3 text-xs text-gray-700 uppercase tracking-wider">
                    <button onClick={() => ordenarPorColumnaNumber(4)}>
                      N° Prestadores
                    </button>
                  </th>
                  <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                    <button onClick={() => ordenarPorColumnaNumber(5)}>
                      Localizaciones Máximas
                    </button>
                  </th>
                  <th className="px-6 py-3 text-xs  text-gray-700  tracking-wider">
                    Accíon
                  </th>
                </tr>
              </thead>
              <tbody id="tablaCuerpo">
                {plans.map((plan, index) => (
                  <tr
                    key={index}
                    className="text-md px-6 py-3  text-center border-b border-purple-300 bg-purple-100  hover:bg-purple-200"
                  >
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      {plan.name}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      {clpNumberFormat.format(plan.amount)}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      {plan.duration}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      {plan.enable ? "Activo" : "Inactivo"}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      {plan.benefit}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      {plan.maxLocation}
                    </td>
                    <td className="px-6 py-3 text-xs  text-gray-700 uppercase tracking-wider">
                      <Link
                        className=" rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                        to={`/plans/modify/${plan.id}`}
                      >
                        Editar
                      </Link>

                      <button
                        type="submit"
                        className={`rounded-md bg-${
                          plan.enable ? "green" : "red"
                        }-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${
                          plan.enable ? "green" : "red"
                        }-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-600`}
                        onClick={() => cambiarStatus(plan.id, plan.enable)}
                      >
                        {plan.enable ? "Habilitado" : "Deshabilitado"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plan;
