import { useEffect, useState } from "react";
import { TextField, Select } from "@radix-ui/themes";

function PlanCreate() {
  const [planCreate, setPlanCreate] = useState({
    name: "",
    duration: 0,
    description: "",
    enable: true,
    maxLocation: 0,
    amount: 0,
  });

  const [errors, setErrors] = useState([]);
  const [correct, setcorrect] = useState(null);
  function createNewPlan(planCreate) {
    fetch(`${process.env.REACT_APP_ANDI_API}/api/v1/plans`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
      body: JSON.stringify({
        name: planCreate.name,
        duration: parseInt(planCreate.duration),
        description: planCreate.description,
        enable: Boolean(planCreate.enable),
        maxLocation: parseInt(planCreate.maxLocation),
        amount: parseInt(planCreate.amount),
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
          throw new Error("La solicitud Post fallo");
        }
        return response.status;
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPlanCreate({
      ...planCreate,
      [name]: value,
    });
  };

  const handleSelectChange = (property, value) => {
    setPlanCreate((planCreate) => ({
      ...planCreate,
      [property]: value,
    }));
  };

  const handleSubmitButtom = () => {
    //event.preventDefault();
    setcorrect(null);
    setErrors([]);
    createNewPlan(planCreate);
  };

  return (
    <div className="container  mx-auto bg-purple-50">
      <h1 className=" text-3xl font-semibold  ml-8 pb-4 pt-4 ">Crear Plan</h1>
      <div>
        {errors.length > 0 && (
          <div role="alert" className="p-4">
            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Error
            </div>
            {errors.map((data) => (
              <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 w-80">
                <p>
                  {data.code}: {data.message}
                </p>
              </div>
            ))}
          </div>
        )}
        {correct === 200 && (
          <div role="alert" className="p-4">
            <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Creado
            </div>

            <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 w-80">
              <p>el plan fue creado correctamente</p>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col ml-8">
        <div className="flex flex-row">
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Nombre
            </p>
            <TextField.Root
              type="text"
              name="name"
              className="w-48"
              value={planCreate.name}
              onChange={handleInputChange}
              placeholder="ingrese nombre"
            />
          </div>
          <div className="mb-8 mr-8">
            <p>Descripción</p>
            <TextField.Root
              type="text"
              name="description"
              className="w-48"
              value={planCreate.description}
              onChange={handleInputChange}
              placeholder="ingrese Descripción"
            />
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Duración
            </p>
            <TextField.Root
              type="number"
              name="duration"
              className="w-48"
              value={planCreate.duration}
              onChange={handleInputChange}
              min="1"
              placeholder="ingrese la Duración"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Precio
            </p>
            <TextField.Root
              type="number"
              name="amount"
              className="w-48"
              value={planCreate.amount}
              onChange={handleInputChange}
              min="1"
              placeholder="ingrese la Duración"
            />
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Estado
            </p>
            <Select.Root
              defaultValue={true}
              onValueChange={(val) => handleSelectChange("enable", val)}
            >
              <Select.Trigger
                placeholder="Estado"
                className="!min-w-48 !max-w-48"
                value={planCreate.enable}
              />
              <Select.Content>
                <Select.Group>
                  {[
                    { value: false, label: "Deshabilitado" },
                    { value: true, label: "Habilitado" },
                  ].map((r, index) => (
                    <Select.Item key={index} value={r.value}>
                      {r.label}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
          <div className="">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Cantidad de Zonas
            </p>
            <TextField.Root
              type="number"
              className="w-48"
              name="maxLocation"
              value={planCreate.maxLocation}
              onChange={handleInputChange}
              placeholder="ingrese una cantidad"
              min="1"
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={handleSubmitButtom}
        className="ml-8 mb-4  mt-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-indigo-600"
      >
        Crear Plan
      </button>
    </div>
  );
}
export default PlanCreate;
