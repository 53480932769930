import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function SurveysUsers() {
  let { id } = useParams();

  const [surveysUsers, setUsersSurveys] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/surveys/${id}`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setUsersSurveys(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modal, setModal] = useState(false);
  const openModal = (user) => {
    setSelectedUser(user);
    setModal(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModal(false);
  };
  return (
    <div className="container mx-auto bg-purple-50">
      <div className="flex flex-col ml-8">
        <div className="flex-row ">
          <h1 className="text-3xl mb-4 mt-4">
            Respuestas - {surveysUsers.question}
          </h1>
        </div>

        <div className="relative w-full flex flex-col shadow-lg mb-6 mt-4 bg-purple-50">
          <div className=" flex  bg-transperant flex-nowrap items-center flex-grow">
            <table className="w-full">
              <thead>
                <tr className="border border-solid border-l-0 bg-purple-300  text-left text-gray-700">
                  <th className="w-1/12 pl-8">foto</th>
                  <th className="w-2/12 pl-8 ">Prestador</th>
                  <th className="w-8/12 pl-8 ">Fecha de Creacion</th>
                  <th className="w-2/12 pl-8 ">Accion</th>
                </tr>
              </thead>
              <tbody>
                {surveysUsers.user &&
                  surveysUsers.user.map((surveyUser) => (
                    <tr className="border-b border-purple-300 bg-purple-100  hover:bg-purple-200 text-wrap">
                      <td className="px-6  text-xs  text-gray-700 uppercase ">
                        {surveyUser.userAvatar && (
                          <img
                            className="w-12 h-12 rounded-full"
                            src={
                              surveyUser.userAvatar
                                ? `${process.env.REACT_APP_ANDI_API}/public/avatar/${surveyUser.userAvatar}`
                                : null
                            }
                          />
                        )}
                      </td>
                      <td className="px-6  text-gray-700 uppercase ">
                        {surveyUser.userRut}
                      </td>
                      <td className="px-6  text-gray-700 uppercase ">
                        {surveyUser.createAt}
                      </td>
                      <td className="px-6   text-gray-700 uppercase tracking-wider">
                        <button
                          onClick={() => openModal(surveyUser)}
                          className=" rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                        >
                          Ver
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {modal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex  justify-center z-50 ">
          <div className="modal-content bg-purple-50 p-6  shadow-md w-3/4 h-3/4 mt-20">
            <h2 className="text-xl font-bold mb-4">{selectedUser.userName}</h2>
            <p className="mb-4">{surveysUsers.question}</p>
            <p className=" p-2 border border-gray-300 rounded text-wrap">
              {selectedUser.answer}
            </p>
            <div className="flex justify-center">
              <button
                className=" rounded-md bg-purple-600 px-4 py-0 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                onClick={closeModal}
              >
                salir
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SurveysUsers;
