import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function UserBenefit() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/benefit`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setUsers(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  function cambiarStatus(id, originState) {
    const state = originState ? false : true;

    const updatedUser = users.map((user) => {
      if (user.id === id) {
        return { ...user, state: state };
      } else {
        return user;
      }
    });

    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/benefit/enable/${id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
        body: JSON.stringify({
          state: state,
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setUsers(updatedUser);
        }
        if (!response.ok) {
          throw new Error("La solicitud PUT falló");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(5);
  function handleUsersPerPageChange(event) {
    setUsersPerPage(Number(event.target.value));
    setCurrentPage(1);
  }
  function renderizarBotonesPaginacion() {
    const totalPages = Math.ceil(users.length / usersPerPage);
    const botones = [];

    for (let i = 1; i <= totalPages; i++) {
      botones.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`mr-2 ${
            currentPage === i
              ? "bg-purple-600 text-white"
              : "bg-purple-200 text-purple-900"
          } px-3 py-1 rounded-md`}
        >
          {i}
        </button>
      );
    }

    return botones;
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const [ordenAscendente, setOrdenAscendente] = useState(true);
  function ordenarPorboolean() {
    const tablaCuerpo = document.getElementById("tablaCuerpo");
    const filas = Array.from(tablaCuerpo.getElementsByTagName("tr"));

    // Eliminar el slice(1) para incluir todas las filas, incluida la primera fila (encabezados)
    const datosFilas = filas.map((fila) => ({
      fila,
      estado:
        fila.getElementsByTagName("td")[2].textContent.trim() === "Activo",
    }));

    // Ordenar los datos por estado (activos primero, inactivos después)
    datosFilas.sort((a, b) => (a.estado === b.estado ? 0 : a.estado ? -1 : 1));

    // Reordenar las filas en el cuerpo de la tabla
    for (const { fila } of datosFilas) {
      tablaCuerpo.appendChild(fila);
    }
  }

  function ordenarPorColumnaString(number) {
    const tablaCuerpo = document.getElementById("tablaCuerpo");

    // Comprobar si se encontró el elemento con el ID "tablaCuerpo"
    if (!tablaCuerpo) return;

    const filas = Array.from(tablaCuerpo.getElementsByTagName("tr"));

    // Eliminar el slice(1) para incluir todas las filas, incluida la primera fila (encabezados)
    const datosFilas = filas.map((fila) => ({
      fila,
      valor: fila.getElementsByTagName("td")[number].textContent,
    }));

    // Ordenar los datos por el valor de la columna especificada
    datosFilas.sort((a, b) => {
      // Comparar las cadenas de texto utilizando localeCompare
      return ordenAscendente
        ? a.valor.localeCompare(b.valor)
        : b.valor.localeCompare(a.valor);
    });

    // Limpiar la tabla antes de reordenar las filas
    while (tablaCuerpo.firstChild) {
      tablaCuerpo.removeChild(tablaCuerpo.firstChild);
    }

    // Reordenar las filas en el cuerpo de la tabla
    for (const { fila } of datosFilas) {
      tablaCuerpo.appendChild(fila);
    }

    // Cambiar la dirección del orden para la próxima vez
    setOrdenAscendente(!ordenAscendente);
  }

  return (
    <div className="mt-4  justify-center bg-purple-50">
      <div className="">
        <div className="ml-8">
          <h1 className="mb-4 font-semibold text-3xl">Prestadores</h1>
          <Link
            className="  h-1/2 mb-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
            to={`/benefit/createUserBenefit`}
          >
            Crear Prestador
          </Link>
        </div>
        <div className="">
          <div className="mt-4 mb-4 ml-8">
            <label>
              Cantidad de búsqueda
              <select
                id="usersPerPage"
                name="usersPerPage"
                value={usersPerPage}
                onChange={handleUsersPerPageChange}
                className="mt-2 bg-purple-50  px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block rounded-md sm:text-sm focus:ring-1 "
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </label>
          </div>
          <div className="">
            <div> </div>
            <div className="block bg-transperant m-4 p-4 w-full overflow-x-auto ">
              <table className="w-full">
                <thead>
                  <tr className="border border-solid border-l-0 bg-purple-300  text-center ">
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      Foto
                    </th>
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      <button onClick={() => ordenarPorColumnaString(1)}>
                        Nombre
                      </button>
                    </th>
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      <button onClick={() => ordenarPorboolean(2)}>
                        Verificado
                      </button>
                    </th>
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      <button onClick={() => ordenarPorboolean(3)}>
                        Estado
                      </button>
                    </th>
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      En línea
                    </th>
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      <button onClick={() => ordenarPorColumnaString(5)}>
                        Región
                      </button>
                    </th>
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      <button onClick={() => ordenarPorColumnaString(6)}>
                        Plan
                      </button>
                    </th>
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      <button onClick={() => ordenarPorColumnaString(7)}>
                        Servicio
                      </button>
                    </th>
                    <th className="px-6 py-3 text-xs text-gray-700  tracking-wider">
                      Acción
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y" id="tablaCuerpo">
                  {currentUsers.map((user) => (
                    <tr
                      key={user.id}
                      className="text-md px-6 py-3  text-center border-b border-purple-300 bg-purple-100  hover:bg-purple-200"
                    >
                      <td className=" px-4 text-center whitespace-nowrap">
                        {user.image && (
                          <img
                            className="w-12 h-12 rounded-full"
                            src={`${process.env.REACT_APP_ANDI_API}/public/avatar/${user.image}`}
                          />
                        )}
                      </td>
                      <td className="text-center whitespace-nowrap">
                        {user.name}
                      </td>
                      <td className="text-center whitespace-nowrap">
                        {user.verified ? "Si" : "No"}
                      </td>
                      <td className="text-center whitespace-nowrap pr-4">
                        {user.state ? "Activo" : "Desactivado"}
                      </td>
                      <td className="text-center whitespace-nowrap pr-4">
                        {user.online ? "En linea" : "Desconectado"}
                      </td>
                      <td className="text-center whitespace-nowrap">
                        {user.region}
                      </td>
                      <td className="text-center whitespace-nowrap">
                        {user.plan || "N/A"}
                      </td>
                      <td className="text-center whitespace-nowrap pr-4">
                        {user.service}
                      </td>
                      <td className="text-center whitespace-nowrap">
                        <Link
                          className=" rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-500"
                          to={`/benefit/modify/${user.id}`}
                        >
                          Editar
                        </Link>
                        <button
                          type="submit"
                          className={`rounded-md bg-${
                            user.state ? "green" : "red"
                          }-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${
                            user.state ? "green" : "red"
                          }-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-600`}
                          onClick={() => cambiarStatus(user.id, user.state)}
                        >
                          {user.state ? "Habilitado" : "Deshabilitado"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-center pb-4">
          {renderizarBotonesPaginacion()}
        </div>
      </div>
    </div>
  );
}
