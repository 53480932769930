import { Link, NavLink } from "react-router-dom";

const Routers = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Planes",
    to: "/plans",
  },
  {
    name: "Prestadores",
    to: "/benefit",
  },
  {
    name: "Pagos",
    to: "/payments",
  },
  {
    name: "Notificaciones",
    to: "/notification",
  },
  {
    name: "Encuestas",
    to: "/surveys",
  }
];
export default function Nav() {
  return (
    <nav className="bg-purple-900">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {Routers.map((rute) => (
                  <NavLink
                    key={rute.to}
                    to={rute.to}
                    className={({ isActive }) =>
                      `text-white rounded-md px-3 py-2 text-sm font-medium ${
                        isActive ? "bg-purple-800" : ""
                      }`
                    }
                    aria-current="page"
                  >
                    {rute.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
