import LoadingIcon from "./LoadingIcon";

const Button = ({ isLoading, onClick, label }) => {
  return (
    <button
      className={`pl-4 pr-4 py-1 w-full text-center
border rounded ${
        isLoading
          ? "border-gray-300 text-gray-300"
          : "border-violet-600 text-violet-600"
      } relative z-10
before:absolute before:left-0 before:top-0 before:bg-violet-600 before:w-0 before:h-full before:z-0
before:transition-all before:duration-300
${!isLoading && "hover:text-white hover:before:left-0 hover:before:w-full"}`}
      onClick={onClick}
      disabled={isLoading}
    >
      <span className="relative z-10">
        {isLoading ? <LoadingIcon /> : label}
      </span>
    </button>
  );
};

export default Button;
