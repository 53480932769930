import { useEffect, useState } from "react";

function SurveysCreate() {
  const [surveysCreate, setSurveysCreate] = useState({
    question: "",
  });
  const [errors, setErrors] = useState([]);
  const [correct, setcorrect] = useState(null);
  function createNewSurveys() {
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/surveys`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
        body: JSON.stringify({
          question: surveysCreate.question,
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
          throw new Error("La solicitud Post fallo");
        }
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSurveysCreate({
      ...surveysCreate,
      [name]: value,
    });
  };
  const handleSubmitButtom = () => {
    //event.preventDefault();
    setCleared(false);
    setcorrect(null);
    setErrors([]);
    createNewSurveys();
  };

  const [surveysCreateCleared, setSurveysCreateCleared] = useState({
    question: "",
  });

  const [cleared, setCleared] = useState(false);
  useEffect(() => {
    if (correct === 200 && !cleared) {
      setSurveysCreate(surveysCreateCleared);
      setCleared(true);
    }
  }, [correct, setSurveysCreate, surveysCreateCleared]);
  return (
    <div className="container  mx-auto bg-purple-50">
      <h1 className=" text-3xl font-semibold  ml-8 pb-4 pt-4 ">
        Crear Encuesta
      </h1>

      <div className="ml-4">
        {errors.length > 0 && (
          <div role="alert" className="p-4">
            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Error
            </div>
            {errors.map((data) => (
              <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 w-80">
                <p>
                  {data.code}: {data.message}
                </p>
              </div>
            ))}
          </div>
        )}
        {correct === 200 && (
          <div role="alert" className="p-4">
            <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Creado
            </div>

            <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 w-80">
              <p>la encuesta fue creada correctamente</p>
            </div>
          </div>
        )}
      </div>
      <div className="flex ml-8">
        <div className="flex-col mr-8">
          <div className="mb-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Pregunta
            </p>
            <input
              type="text"
              name="question"
              value={surveysCreate.question}
              onChange={handleInputChange}
              className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
              placeholder="ingrese la pregunta"
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={handleSubmitButtom}
        className="ml-8 mb-4 mt-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-indigo-600"
      >
        Crear Plan
      </button>
    </div>
  );
}
export default SurveysCreate;
