const Alert = ({ title, message, type, onCloseAlert }) => {
  const types = { warn: { borderColor: "border-l-yellow-400" } };

  return (
    <div className="py-2 mb-4 bg-white">
      <div className="container">
        <div
          className={`${types[type].borderColor} flex items-center rounded-md border-l-[6px] bg-white p-2 pl-6`}
        >
          <div className="flex items-center justify-between w-full">
            <div>
              <h3 className="mb-1 text-xs text-black font-bold">{title}</h3>
              <p className="text-xs text-body-color">{message}</p>
            </div>
            <div className="ml-4">
              <button
                className="duration-300 text-dark-3 hover:text-dark"
                onClick={onCloseAlert}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 10L18.625 2.375C18.9062 2.09375 18.9062 1.65625 18.625 1.375C18.3438 1.09375 17.9062 1.09375 17.625 1.375L10 9L2.375 1.375C2.09375 1.09375 1.65625 1.09375 1.375 1.375C1.09375 1.65625 1.09375 2.09375 1.375 2.375L9 10L1.375 17.625C1.09375 17.9062 1.09375 18.3438 1.375 18.625C1.5 18.75 1.6875 18.8438 1.875 18.8438C2.0625 18.8438 2.25 18.7812 2.375 18.625L10 11L17.625 18.625C17.75 18.75 17.9375 18.8438 18.125 18.8438C18.3125 18.8438 18.5 18.7812 18.625 18.625C18.9062 18.3438 18.9062 17.9062 18.625 17.625L11 10Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
