import { useEffect, useState } from "react";

function NotificationCreate() {
  useEffect(() => {
    const form = document.querySelector("form");
    form.addEventListener("submit", handleSubmit);

    return () => {
      form.removeEventListener("submit", handleSubmit);
    };
  }, []);
  const [errors, setErrors] = useState([]);
  const [correct, setcorrect] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    setcorrect(null);
    setErrors([]);
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/notification`,
      {
        method: "post",
        body: formData,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
          form.reset();
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
          throw new Error("La solicitud Post fallo");
        }
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  };

  return (
    <div className="container mx-auto bg-purple-50">
      <div className="ml-4">
        <h1 className=" text-3xl font-semibold  ml-4 pb-4 pt-4 ">
          Crear Notificación
        </h1>

        <div>
          {errors.length > 0 && (
            <div role="alert" className="p-4">
              <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-80">
                Error
              </div>
              {errors.map((data) => (
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 w-80">
                  <p>
                    {data.code}: {data.message}
                  </p>
                </div>
              ))}
            </div>
          )}
          {correct === 200 && (
            <div role="alert" className="p-4">
              <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2 w-80">
                Creado
              </div>

              <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 w-80">
                <p>La notificación fue creada correctamente</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex ml-8 ">
        <form id="form">
          <div className="flex-col mr-8">
            <div className="mb-8">
              <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
                Titulo
              </p>
              <input
                type="text"
                name="title"
                //onChange={handleInputChange}
                className="bg-purple-50 mt-1 px-3 py-2  border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block w-48 rounded-md sm:text-sm focus:ring-1"
                placeholder={`ingrese el titulo`}
              />
            </div>
            <div className="mb-8">
              <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
                Texto
              </p>
              <input
                type="text"
                name="text"
                //onChange={handleInputChange}
                className="bg-purple-50 w-48 mt-1 px-3 py-2 border shadow-sm border-purple-300 placeholder-purple-900 focus:outline-none focus:border-slate-300 focus:ring-purple-500 block rounded-md sm:text-sm focus:ring-1 "
                placeholder={`ingrese el texto`}
              />
            </div>
          </div>
          <div className="flex-col mr-8">
            <div className="mb-8">
              <input
                type="file"
                className="pb-1 border-none"
                name="icon_file"
              ></input>

              <button
                type="submit"
                className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-green-500"
              >
                agregar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NotificationCreate;
