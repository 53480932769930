import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Input from "../components/basic/Input";
import { TextField, Select } from "@radix-ui/themes";

function UserBenefitCreate() {
  const [service, setService] = useState([]);
  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/services`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setService(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  const [region, setRegion] = useState([]);
  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/regions`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setRegion(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  const [plans, setPlans] = useState([]);
  useEffect(() => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/plans`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setPlans(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  const [formDataBenefit, setFormDataBenefit] = useState({
    firstName: "",
    secondName: "",
    lastName: "",
    secondLastName: "",
    password: "",
    serviceId: 1,
    phone: 0,
    sisCode: "",
    email: "",
    enabled: true,
    regionId: 7,
    carnetSerialNumber: "",
    rut: "",
    isVerified: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "enable" ? value === "true" : value;

    setFormDataBenefit((formDataBenefit) => ({
      ...formDataBenefit,
      [name]: newValue,
    }));
  };

  const handleSelectChange = (property, value) => {
    setFormDataBenefit((formDataBenefit) => ({
      ...formDataBenefit,
      [property]: value,
    }));
  };

  const [errors, setErrors] = useState([]);
  const [correct, setcorrect] = useState();

  function cambiarDatos(user) {
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/admin/user/benefit/`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },
        body: JSON.stringify({
          firstName: user.firstName,
          lastName: user.lastName,
          secondName: user.secondName,
          secondLastName: user.secondLastName,
          password: user.password,
          serviceId: parseInt(user.serviceId),
          phone: user.phone ? user.phone.toString() : null,
          sisCode: user.sisCode,
          email: user.email,
          enabled: Boolean(user.enabled),
          regionId: parseInt(user.regionId),
          carnetSerialNumber: user.carnetSerialNumber,
          rut: user.rut,
          isVerified: Boolean(user.isVerified),
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
          throw new Error("La solicitud PUT falló");
        }
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud PUT:", error);
      });
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    setcorrect(null);
    setErrors([]);
    cambiarDatos(formDataBenefit);
  };

  return (
    <div className="container mx-auto bg-white p-10">
      <div>
        <h1 className=" text-3xl font-semibold  ml-8 pb-4 pt-4 ">
          Crear prestador
        </h1>
        {errors.length > 0 && (
          <div role="alert" className="p-4">
            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Error
            </div>
            {errors.map((data) => (
              <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 w-80">
                <p>
                  {data.code}: {data.message}
                </p>
              </div>
            ))}
          </div>
        )}
        {correct === 200 ? (
          <div role="alert" className="p-4">
            <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2 w-80">
              Creado
            </div>

            <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 w-80">
              <p>el Prestador fue creado correctamente</p>
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row mr-8 ml-8">
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Primer Nombre
            </p>
            <TextField.Root
              className="w-48"
              placeholder="Elsa"
              name="firstName"
              value={formDataBenefit.firstName}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-8 mr-8">
            <p>Segundo Nombre</p>
            <TextField.Root
              name="secondName"
              className="w-48"
              value={formDataBenefit.secondName}
              onChange={handleInputChange}
              placeholder={"Patito"}
            />
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Primer Apellido
            </p>
            <TextField.Root
              name="lastName"
              className="w-48"
              value={formDataBenefit.lastName}
              onChange={handleInputChange}
              placeholder={"Primer apellido"}
            />
          </div>
          <div className="mb-8 mr-8">
            <p>Segundo Apellido</p>
            <TextField.Root
              name="secondLastName"
              className="w-48"
              value={formDataBenefit.secondLastName}
              onChange={handleInputChange}
              placeholder={"Segundo apellido"}
            />
          </div>
        </div>
        <div className="flex flex-row mr-8 ml-8">
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              SIS
            </p>
            <TextField.Root
              className="w-48"
              maxLength={12}
              onChange={handleInputChange}
              value={formDataBenefit.sisCode}
              type="text"
              name="sisCode"
              placeholder={`111333444`}
            />
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Región
            </p>
            <Select.Root
              defaultValue={7}
              onValueChange={(val) => handleSelectChange("regionId", val)}
            >
              <Select.Trigger
                name="region"
                placeholder="Regiones"
                value={formDataBenefit.regionId}
                className="!min-w-48 !max-w-48"
              />
              <Select.Content>
                <Select.Group>
                  {region.map((r, index) => (
                    <Select.Item key={index} value={r.id}>
                      {r.name}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Rut
            </p>
            <TextField.Root
              className="w-48"
              type="text"
              name="rut"
              value={formDataBenefit.rut}
              onChange={handleInputChange}
              placeholder={`11.111.111-8`}
            />
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Contraseña
            </p>
            <TextField.Root
              className="w-48"
              type="password"
              name="password"
              value={formDataBenefit.password}
              onChange={handleInputChange}
              placeholder={"ingrese la contraseña"}
            />
          </div>
        </div>
        <div className="flex flex-row mr-8 ml-8">
          <div className="mb-8 mr-8">
            <p>Servicio</p>
            <Select.Root
              defaultValue={1}
              onValueChange={(val) => handleSelectChange("serviceId", val)}
            >
              <Select.Trigger
                placeholder="Servicios"
                className="!min-w-48 !max-w-48"
                value={formDataBenefit.serviceId}
              />
              <Select.Content>
                <Select.Group>
                  {service.map((r, index) => (
                    <Select.Item key={index} value={r.id}>
                      {r.name}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Email
            </p>
            <TextField.Root
              className="w-48"
              type="email"
              name="email"
              value={formDataBenefit.email}
              onChange={handleInputChange}
              placeholder={`example@gmail.com`}
            />
          </div>
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600">
              Número de Documento
            </p>
            <TextField.Root
              className="w-48"
              type="text"
              name="carnetSerialNumber"
              value={formDataBenefit.carnetSerialNumber}
              onChange={handleInputChange}
              maxLength={11}
              placeholder={`111111`}
            />
          </div>
          <div className="mb-8 mr-8">
            <p>Verificado</p>
            <Select.Root
              defaultValue={false}
              onValueChange={(val) => handleSelectChange("isVerified", val)}
            >
              <Select.Trigger
                placeholder="Es verificado?"
                className="!min-w-48 !max-w-48"
                value={formDataBenefit.isVerified}
              />
              <Select.Content>
                <Select.Group>
                  {[
                    { value: false, label: "No" },
                    { value: true, label: "Si" },
                  ].map((r, index) => (
                    <Select.Item key={index} value={r.value}>
                      {r.label}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
        </div>
        <div className="flex flex-row mr-8 ml-8">
          <div className="mb-8 mr-8">
            <p>Teléfono</p>
            <TextField.Root
              type="number"
              name="phone"
              className="w-48"
              value={formDataBenefit.number}
              min="1"
              onChange={handleInputChange}
              placeholder={`+569133`}
            />
          </div>
          <div className="mb-8 mr-8">
            <p>Estado</p>
            <Select.Root
              defaultValue={true}
              onValueChange={(val) => handleSelectChange("enabled", val)}
            >
              <Select.Trigger
                placeholder="Estado"
                className="!min-w-48 !max-w-48"
                value={formDataBenefit.enabled}
              />
              <Select.Content>
                <Select.Group>
                  {[
                    { value: false, label: "Deshabilitado" },
                    { value: true, label: "Habilitado" },
                  ].map((r, index) => (
                    <Select.Item key={index} value={r.value}>
                      {r.label}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>
        </div>
        <div className="mr-8 ml-8">
          <div className="mb-8">
            <button
              type="butom"
              onClick={handleSubmit}
              className="rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-purple-600"
            >
              Guardar Cambios
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserBenefitCreate;
