import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  TextField,
  Select,
  Table,
  IconButton,
  Button,
  Flex,
  AlertDialog,
} from "@radix-ui/themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

function PlanModify() {
  let { id } = useParams();
  const [plan, setPlan] = useState({
    name: "",
    description: "",
    duration: 0,
    enable: true,
    maxLocation: 0,
    amount: 0,
    features: [],
  });
  const [planFeature, setPlanFeature] = useState({
    icon: "",
    description: "",
    enable: true,
  });

  const getPlanById = () => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/plans/${id}`;
    fetch(url, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setPlan(res);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const addFeature = () => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/plans/${id}/feature`;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(planFeature),
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        if (res.status == 200) {
          getPlanById();
          setPlanFeature({
            icon: "",
            description: "",
            enable: true,
          });
        }
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const deleteFeature = (id) => {
    const url = `${process.env.REACT_APP_ANDI_API}/api/v1/plans/feature/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
      },
    })
      .then((res) => {
        if (res.status == 200) {
          getPlanById();
        }
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  useEffect(() => {
    getPlanById();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "enable" ? value === "true" : value;

    setPlan((prevPlan) => ({
      ...prevPlan,
      [name]: newValue,
    }));
  };

  const handleInputChangePlanFeature = (event) => {
    const { name, value } = event.target;

    setPlanFeature((prevPlan) => ({
      ...prevPlan,
      [name]: value,
    }));
  };

  const handleSelectChange = (property, value) => {
    setPlan((planCreate) => ({
      ...planCreate,
      [property]: value,
    }));
  };

  const handleSelectChangePlanFeature = (property, value) => {
    setPlanFeature((p) => ({
      ...p,
      [property]: value,
    }));
  };

  const [errors, setErrors] = useState([]);
  const [correct, setcorrect] = useState(null);
  function cambiarDatos(id, formData) {
    fetch(
      `${process.env.REACT_APP_ANDI_API}/api/v1/plans/${id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("andi-session")).token,
        },

        body: JSON.stringify({
          name: formData.name.toString(),
          duration: parseInt(formData.duration),
          description: formData.description,
          enable: Boolean(formData.enable),
          maxLocation: parseInt(formData.maxLocation),
          amount: parseInt(formData.amount),
        }),
      }
    )
      .then((response) => {
        if (response.status == 200) {
          setcorrect(response.status);
        } else {
          response.json().then((data) => {
            setErrors(data);
          });
        }

        if (!response.ok) {
          throw new Error("La solicitud PUT falló");
        }
        return response.json();
      })

      .catch((error) => {
        console.error(error);
      });
  }
  const handleSubmit = () => {
    setcorrect(null);
    setErrors([]);
    cambiarDatos(id, plan);
  };

  return (
    <div className="container mx-auto px-4 bg-purple-50 h-screen">
      <h1 className=" text-3xl font-semibold  ml-4 pb-4 pt-4 ">Modificar</h1>
      <div className="flex">
        <div className="flex-row">
          {errors.length > 0 && (
            <div role="alert" className="p-4">
              <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2 w-80">
                Error
              </div>
              {errors.map((data) => (
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 w-80">
                  <p>
                    {data.code}: {data.message}
                  </p>
                </div>
              ))}
            </div>
          )}
          {correct === 200 && (
            <div role="alert" className="p-4">
              <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2 w-80">
                modificado
              </div>

              <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700 w-80">
                <p>el Plan fue modificado correctamente</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="mb-8 mr-8">
            <label className="block mb-4">
              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                Nombre
              </span>
              <div>
                <TextField.Root
                  type="text"
                  className="w-48"
                  name="name"
                  value={plan.name}
                  onChange={handleInputChange}
                  placeholder={`ingrese el nombre`}
                />
              </div>
            </label>
          </div>
          <div className="mb-8 mr-8">
            <label>
              <span className="block text-sm font-medium text-slate-700">
                Descripción
              </span>
              <TextField.Root
                type="text"
                className="w-48"
                name="description"
                value={plan.description}
                onChange={handleInputChange}
                placeholder={`ingrese la Descripción`}
              />
            </label>
          </div>
          <div className="mb-8 mr-8">
            <label className="block mb-4">
              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                Duración
              </span>
              <TextField.Root
                type="number"
                className="w-48"
                name="duration"
                value={plan.duration}
                onChange={handleInputChange}
                min="1"
                placeholder={`${plan.duration}`}
              />
            </label>
          </div>
        </div>

        <div className="flex flex-row">
          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Precio
            </p>
            <TextField.Root
              type="number"
              name="amount"
              className="w-48"
              value={plan.amount}
              onChange={handleInputChange}
              min="1"
              placeholder="ingrese la Duración"
            />
          </div>

          <div className="mb-8 mr-8">
            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
              Estado
            </p>
            <Select.Root
              value={plan.enable}
              onValueChange={(val) => handleSelectChange("enable", val)}
            >
              <Select.Trigger
                placeholder="Estado"
                className="!min-w-48 !max-w-48"
                value={plan.enable}
              />
              <Select.Content>
                <Select.Group>
                  {[
                    { value: false, label: "Deshabilitado" },
                    { value: true, label: "Habilitado" },
                  ].map((r, index) => (
                    <Select.Item key={index} value={r.value}>
                      {r.label}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>

          <div className="mb-8 mr-8">
            <label className="block mb-4">
              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600  block font-medium text-slate-700">
                Cantidad de Zonas
              </span>
              <TextField.Root
                type="number"
                className="w-48"
                name="maxLocation"
                value={plan.maxLocation}
                onChange={handleInputChange}
                min="1"
                placeholder={`${plan.maxLocation || "ingrese la cantidad"}`}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="flex flex-col border-t mb-8 py-4">
        <h1 className="block font-bold pb-4">Características</h1>
        <div className="flex flex-row">
          <div className="mb-8 mr-8">
            <Table.Root>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>Icon</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Descripción</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Estado</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Acción</Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {plan.features.map((p, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{p.icon}</Table.Cell>
                    <Table.Cell>
                      <span
                        dangerouslySetInnerHTML={{ __html: p.description }}
                      ></span>
                    </Table.Cell>
                    <Table.Cell>
                      {p.enable ? "Habilitado" : "Deshabilitado"}
                    </Table.Cell>
                    <Table.Cell>
                      <IconButton
                        color="red"
                        onClick={() => deleteFeature(p.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                ))}

                <Table.Row>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>
                    <AlertDialog.Root>
                      <AlertDialog.Trigger>
                        <IconButton>
                          <FontAwesomeIcon icon={faPlus} />
                        </IconButton>
                      </AlertDialog.Trigger>
                      <AlertDialog.Content maxWidth="450px">
                        <AlertDialog.Title>
                          Agregar característica
                        </AlertDialog.Title>

                        <div className="flex flex-col">
                          <div className="mt-4 mb-2 mr-8">
                            <label className="block mb-4">
                              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                                Icono
                              </span>
                              <div>
                                <TextField.Root
                                  type="text"
                                  className="w-48"
                                  name="icon"
                                  value={planFeature.icon}
                                  onChange={handleInputChangePlanFeature}
                                  placeholder={`Icono`}
                                />
                              </div>
                            </label>
                          </div>
                          <div className="mb-2 mr-8">
                            <label className="block mb-4">
                              <span className="after:content-['*'] after:ml-0.5 after:text-purple-600 block text-sm font-medium text-slate-700">
                                Descripción
                              </span>
                              <div>
                                <TextField.Root
                                  type="text"
                                  className="w-48"
                                  name="description"
                                  value={planFeature.description}
                                  onChange={handleInputChangePlanFeature}
                                  placeholder={`Descripción`}
                                />
                              </div>
                            </label>
                          </div>
                          <div className="mb-8 mr-8">
                            <p className="after:content-['*'] after:ml-0.5 after:text-purple-600 ">
                              Estado
                            </p>
                            <Select.Root
                              defaultValue={true}
                              onValueChange={(val) =>
                                handleSelectChangePlanFeature("enable", val)
                              }
                            >
                              <Select.Trigger
                                placeholder="Estado"
                                className="!min-w-48 !max-w-48"
                                value={planFeature.enable}
                              />
                              <Select.Content>
                                <Select.Group>
                                  {[
                                    { value: false, label: "Deshabilitado" },
                                    { value: true, label: "Habilitado" },
                                  ].map((r, index) => (
                                    <Select.Item key={index} value={r.value}>
                                      {r.label}
                                    </Select.Item>
                                  ))}
                                </Select.Group>
                              </Select.Content>
                            </Select.Root>
                          </div>
                        </div>
                        <Flex gap="3" mt="4" justify="end">
                          <AlertDialog.Cancel>
                            <Button variant="soft" color="gray">
                              Cancel
                            </Button>
                          </AlertDialog.Cancel>
                          <AlertDialog.Action>
                            <Button
                              variant="solid"
                              color="purple"
                              onClick={addFeature}
                            >
                              Agregar
                            </Button>
                          </AlertDialog.Action>
                        </Flex>
                      </AlertDialog.Content>
                    </AlertDialog.Root>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table.Root>
          </div>
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          onClick={handleSubmit}
          className="ml-4 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:divide-indigo-600"
        >
          Guardar Cambios
        </button>
      </div>
    </div>
  );
}

export default PlanModify;
